import React, { useMemo } from "react"
import { graphql } from 'gatsby'
import { Link } from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pagination from "../components/pagination"
import { replaceHtml } from '../utility/utils'
import TabellaFondi from "../components/tabella-fondi"
import TabellaProspetti from "../components/tabella-prospetti"
import TabellaRegolamenti from "../components/tabella-regolamenti"
import useHashPagination from '../utility/useHashPagination'


const TemplatePaginaQuotazioni = ({ pageContext: { breadcrumbs, titoloPadre, pagineSorelle, myPath }, data, location }) => {
  const { pagina } = data;
  const { testo, titolo, titoloInPagina, tabellaFondi, tabellaProspetti, tabellaRegolamenti } = pagina;
  const testoMd = testo && testo.childMarkdownRemark.html;
  const testoHtml = useMemo(() => testoMd ? replaceHtml(testoMd) : null, [testoMd]);
  const resultsPerPage = 20;
  const tableLength = (tabellaFondi && tabellaFondi.length) || (tabellaProspetti && tabellaProspetti.length) || (tabellaRegolamenti && tabellaRegolamenti.length);
  const length = Math.ceil(tableLength / resultsPerPage);
  const currentPage = useHashPagination(location, length);

  return (
    <Layout data={data}>
      <SEO page={pagina} data={data} pagination={{ path: myPath, current: currentPage, length }} />

      <div id="main" role="main">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prodotto template-quotazioni">
                <div className="row">
                  <div className="col-12">
                    {/* Breadcrumb */}
                    <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
                      <ol className="breadcrumbs__list" itemType="http://schema.org/BreadcrumbList" itemScope="itemscope">
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level="1" itemProp="item">
                            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Homepage</span></Link>
                          </div>
                          <meta content="1" itemProp="position" />
                        </li>
                        {breadcrumbs.map((item, i) => (
                          <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement" key={i}>
                            <div aria-level={i + 2} itemProp="item">
                              <Link to={item.link} title={item.name} className="breadcrumbs__link" itemProp="url"><span itemProp="name">{item.name}</span></Link>
                            </div>
                            <meta content={i + 2} itemProp="position" />
                          </li>
                        ))}
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level={breadcrumbs.length + 2} itemProp="item">
                            <span itemProp="name">{titoloPadre}</span>
                          </div>
                          <meta content={breadcrumbs.length + 2} itemProp="position" />
                        </li>
                      </ol>
                    </div>

                    {/* Titolo  */}
                    <div className="d-flex flex-column flex-md-row">
                      <h1 className="prodotto__title">{titoloInPagina || titolo}</h1>
                    </div>

                    {/* Lista pagine sorelle */}
                    <div className="nav-tab">
                      <ul className="nav-tab__list d-md-flex">
                        {pagineSorelle.map((item, i) => (
                          <li key={i} className="nav-tab__item">
                            <Link to={item.path} title={item.titolo} className="nav-tab__link"
                              activeClassName="active">
                              {item.titolo}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Testo */}
                    {testo && <div className="prodotto__text" dangerouslySetInnerHTML={{ __html: testoHtml }}></div>}

                    {/* Tabella "Fondi" */}
                    {tabellaFondi && (
                      <div className="lista-prodotti">
                        <TabellaFondi data={tabellaFondi.slice(currentPage * resultsPerPage, (currentPage + 1) * resultsPerPage)} />
                        {resultsPerPage < tabellaFondi.length && <Pagination current={currentPage} total={tabellaFondi.length} perPage={resultsPerPage} location={location} />}
                      </div>
                    )}

                    {/* Tabella "Prospetti" */}
                    {tabellaProspetti && (
                      <div className="lista-prodotti">
                        <TabellaProspetti data={tabellaProspetti.slice(currentPage * resultsPerPage, (currentPage + 1) * resultsPerPage)} />
                        {resultsPerPage < tabellaProspetti.length && <Pagination current={currentPage} total={tabellaProspetti.length} perPage={resultsPerPage} location={location} />}
                      </div>
                    )}

                    {/* Tabella "Regolamenti" */}
                    {tabellaRegolamenti && (
                      <div className="lista-prodotti">
                        <TabellaRegolamenti data={tabellaRegolamenti.slice(currentPage * resultsPerPage, (currentPage + 1) * resultsPerPage)} />
                        {resultsPerPage < tabellaRegolamenti.length && <Pagination current={currentPage} total={tabellaRegolamenti.length} perPage={resultsPerPage} location={location} />}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TemplatePaginaQuotazioni;

export const query = graphql`
query PaginaQuotazioniQuery($contentful_id: String!){
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  pagina: contentfulLayoutQuotazioni(contentful_id: {eq: $contentful_id}) {
    titolo
    titoloInPagina
    seo {
      friendlyUrl
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
    tabellaFondi {
      fondo
      regolamento {
        title
        description
        file {
          url
        }
        relativeUrl
      }
      rendicontoAnnuale {
        title
        description
        file {
          url
        }
        relativeUrl
      }
    }
    tabellaProspetti {
      anno
      denominazione
      documento {
        title
        description
        file {
          url
        }
        relativeUrl
      }
    }
    tabellaRegolamenti {
      denominazione
      documento {
        title
        description
        file {
          url
        }
        relativeUrl
      }
    }
  }
  quotazioni: contentfulLayoutPaginaInterna(contentful_id: {eq: "1dBGxMWTRWPlthCCXtXD9s"}) {
    titolo
    seo {
      friendlyUrl
    }
  }
}`;