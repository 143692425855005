import React from 'react'
import Pdf from '../images/ico-svg/file-pdf-light.svg'

const TabellaFondi = ({ data }) => (
  <>
    <div className="lista-prodotti__head d-none d-sm-block">
      <div className="row">
        <div className="col-sm-4">Fondo</div>
        <div className="col-sm-4">Rendiconto annuale</div>
        <div className="col-sm-4 text-center">Regolamento</div>
      </div>
    </div>

    <div className="lista-prodotti__body">
      {data.map((item, i) => (
        <div className="lista-prodotti__row" key={i}>
          <div className="row align-items-center">
            <div className="col-sm-4">
              <span className="lista-prodotti__denominazione">{item.fondo}</span>
            </div>
            <div className="col-sm-4">
              <strong className="d-sm-none">Rendiconto annuale: </strong>
              {!!item.rendicontoAnnuale && item.rendicontoAnnuale.map((rendiconto, j) => (
                <div className="lista-prodotti__prospetto" key={j}>
                  <a href={rendiconto.relativeUrl} title={rendiconto.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                    <span className="ico-svg"><Pdf /></span>
                    {rendiconto.description && (<span className="lista-prodotti__desc">{rendiconto.description}</span>)}
                  </a>
                </div>
              ))}
            </div>
            
            <div className="col-sm-4 text-md-center">
              {!!item.regolamento && item.regolamento &&
                <div className="lista-prodotti__prospetto">
                  <strong className="d-sm-none">Regolamento: </strong>
                  <a href={item.regolamento.relativeUrl} title={item.regolamento.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                    <span className="ico-svg"><Pdf /></span>
                    {item.regolamento.description && (<span className="lista-prodotti__desc">{item.regolamento.description}</span>)}
                  </a>
                </div>
               }
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

export default TabellaFondi;