import React from 'react'
import Pdf from '../images/ico-svg/file-pdf-light.svg'

const TabellaRegolamenti = ({ data }) => (
  <>
    <div className="lista-prodotti__head">
      <div className="row">
        <div className="col-8">Gestione separata</div>
        <div className="col-4 text-center">Documento</div>
      </div>
    </div>

    <div className="lista-prodotti__body">
      {data.map((item, i) => (
        <div className="lista-prodotti__row" key={i}>
          <div className="row">
            <div className="col-8">
              <span className="lista-prodotti__denominazione">{item.denominazione}</span>
            </div>
            <div className="col-4">
              <div className="lista-prodotti__prospetto text-center">
                <a href={item.documento.relativeUrl} title={item.documento.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                  <span className="ico-svg"><Pdf /></span>
                  {item.documento.description && (<span className="lista-prodotti__desc">{item.documento.description}</span>)}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

export default TabellaRegolamenti;