import React from 'react'
import Pdf from '../images/ico-svg/file-pdf-light.svg'

const TabellaProspetti = ({ data }) => (
  <>
    <div className="lista-prodotti__head d-none d-sm-block">
      <div className="row">
        <div className="col-sm-5">Gestione separata</div>
        <div className="col-sm-2">Anno</div>
        <div className="col-sm-5">Documentazione</div>
      </div>
    </div>

    <div className="lista-prodotti__body">
      {data.map((item, i) => (
        <div className="lista-prodotti__row" key={i}>
          <div className="row align-items-center">
            <div className="col-sm-5">
              <span className="lista-prodotti__denominazione">
                {item.denominazione}
              </span>
            </div>
            <div className="col-sm-2">
              <span className="lista-prodotti__anno">
                <strong className="d-sm-none">Anno: </strong>
                {item.anno}
              </span>
            </div>
            <div className="col-sm-5">
              <strong className="d-sm-none">Documentazione: </strong>
              {item.documento.map((doc, j) => (
                <div className="lista-prodotti__prospetto" key={j}>
                  <a href={doc.relativeUrl} title={doc.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                    <span className="ico-svg"><Pdf /></span>
                    {doc.description && (<span className="lista-prodotti__desc">{doc.description}</span>)}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

export default TabellaProspetti;